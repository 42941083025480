import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/layout"
import HeroText from "../../components/hero-text"
import Pill from "../../components/pill"

import { GatsbySeo } from "gatsby-plugin-next-seo"
import Image from "gatsby-image"

export default ({ props }) => {
  const data = useStaticQuery(graphql`
    query allProjectsQuery {
      allProjectsJson {
        edges {
          node {
            name
            description
            logo
            url
            languages
            platforms
            frameworks
          }
        }
      }
    }
  `)

  const projects = data.allProjectsJson.edges

  const renderProjects = () => {
    return projects.map(node => {
      const project = node.node

      return (
        <a
          key={project.name}
          href={project.url}
          style={{
            "--sal-delay": "2s", //1000 + (250 * index) / 1000 + "s",
            "--sal-duration": "1s",
          }}
          className="hover:no-underline"
        >
          <div
            style={{
              backgroundImage: "url(/images/project-grid-blue.png)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
            className="max-w-sm rounded-lg overflow-hidden shadow-md hover:shadow-2xl m-4 sm:m-2 bg-white hover:bg-white transform hover:scale-110 transition duration-300 ease-in-out"
          >
            <img
              className="h-40 my-12 mx-auto"
              src={project.logo}
              alt={project.name}
            />
            <div className="px-8 py-6">
              <div className="mb-4">
                {project.platforms.map(item => (
                  <Pill text={item} color="blue" />
                ))}

                {project.frameworks.map(item => (
                  <Pill text={item} color="green" />
                ))}

                {project.languages.map(item => (
                  <Pill text={item} color="yellow" />
                ))}
              </div>
              <div>
                <span className="font-bold inline-block text-black text-2xl mb-2 font-mono tracking-tight">
                  {project.name}
                </span>
              </div>

              <div className="text-gray-500 text-base">
                {project.description}
              </div>
            </div>
          </div>
        </a>
      )
    })
  }

  return (
    <Layout>
      <GatsbySeo title="Projects" />
      <HeroText
        title="Open Source Projects"
        description="Packages and side projects for Laravel, React, React Native and many more"
      />
      <div className="bg-white h-full text-black">
        <div className="container mx-auto sm:py-6 lg:py-12 text-left">
          {renderProjects()}
        </div>
      </div>
    </Layout>
  )
}
